import { appWithTranslation } from "next-i18next";
import "../styles/global.scss";
import "@react-page/editor/lib/index.css";
import "nprogress/nprogress.css";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import CookieBanner from "../components/cookie/CookieBanner";
import Script from "next/script";

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-TD2WV8S" });
  }, []);

  return (
    <>
      <Component {...pageProps} />
      <CookieBanner />
      <Script src="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" />
    </>
  );
}

export default appWithTranslation(MyApp);
